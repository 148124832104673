import { PlayCircleIcon, PlayIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Modal1 from "../components/Modal/Modal1";
import FormInput from "../components/Inputs/FormInput";
import { COMPOSE_EMAIL } from "../services/mailServices";
import { DEFUALT_EMAIL_SENDER } from "../core/constants";
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";

const SkillsForEveryYouth = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [values, setValues] = useState({
    address: "",
    email: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    state: "",
    lga: "",
    interest: "",
    electrical_knowledge: "",
    qualification: "",
  });

  const electrical_knowledge = [
    "No practical and theoretical knowledge",
    "Basic Practical and Theoretical knowledge (Beginners)",
    "Good Theoretical but No Practical knowledge (Semi Pro)",
  ];

  const highest_education_qualification = [
    "First School Leaving Certificate",
    "SSCE",
    "National Diploma",
    "HND/BSC",
    "Other",
  ];

  const interested_courses = [
    "CCTV INSTALLATION",
    "SOLAR & INVERTER",
    "CCTV INSTALLATION INTO MOBILE DEVICE",
    "SOLAR PANEL INSTALLATION",
  ];
  const [modal, setModal] = useState(false);

  const handleFormOnChange = (e) => {
    const { value, id } = e.target;
    setValues({ ...values, [id]: value });
  };

  const handleSubmitRequest = async (e) => {
    e.preventDefault();

    const {
      address,
      email,
      firstname,
      lastname,
      phoneNumber,
      state,
      lga,
      interest,
      electrical_knowledge,
      qualification,
    } = values;

    // alert(JSON.stringify(values));
    if (
      address === "" ||
      email === "" ||
      phoneNumber === "" ||
      firstname === "" ||
      lastname === "" ||
      state === "" ||
      lga === "" ||
      interest === "" ||
      electrical_knowledge === "" ||
      qualification === ""
    ) {
      toast.warn("Some fields are required");
      return;
    }

    setIsProcessing(true);
    const mail = await COMPOSE_EMAIL({
      // to: `  goodluck.kingsley@egoras.ng`,
      to: `${DEFUALT_EMAIL_SENDER}, admin@vitalskillsda.com`,
      subject: `SEETA REQUEST :  `,
      message: `APPILCANT INFORMATION : \n 
        Email: \t ${email} \n
        First Name: \t ${firstname} \n
        Last Name: \t ${lastname} \n
        Phone Number: \t ${phoneNumber} \n
        Sttate of Origin: \t ${state} \n
        LGA: \t ${lga} \n
        Residential Address: \t ${address} \n
        Technical Field of Interest: \t ${interest} \n
        Electrical Experience: \t ${electrical_knowledge} \n
        Highest Education Qualification: \t ${qualification} \n
      `,
      // message: `APPILCANT INFORMATION : \n ${JSON.stringify(values)}`,
      // message: `${firstname} just requested the service of ${staff} \n \n  CUSTOMER DETAILS: \n  FULLNAME: ${fullname} \n ADDRESS: ${address} \n PHONE NUMBER: ${phoneNumber} \n ADDITIONAL INFO: ${extra} `,
    });
    console.log(mail);
    if (!mail.isOk) {
      toast.warn("Please resubmit the form");
      return;
    }

    toast.success("Request submitted successfully.");
    setIsProcessing(false);
    setModal(false);
  };

  const handleEnrolmentFormOnChange = (e) => {
    console.log(e.target.value);
    const { value, id } = e.target;
    setValues({ ...values, [id]: value });
  };

  return (
    <div className="  flex flex-col    ">
      <div className="min-h-[30vh] bg-[url('https://console.vitalskillsda.com/images/bayelsa.jpeg')] bg-no-repeat bg-cover bg-center ">
        <div className=" w-full h-full bg-black/80 relative  backdrop-blur-sm flex justify-center items-center gap-10  px-20 py-36">
          <div className="absolute text-white top-10 right-20 text-6xl uppercase  font-bold">
            <p>
              s<span className="text-red-400">4</span>ey
            </p>

            <p className="text-[10px] absolute -bottom-3 right-0 border-t-2 pt-1 ">
              with Engnr. Gabby
            </p>
          </div>
          <div className="text-white text-7xl font-semibold pt-36 text-center">
            <p className="text-center">
              SKILLS F
              <span className="relative ">
                O
                <PlayIcon className="absolute w-4 top-[42px] right-[19px] font-bold text-red-400" />
              </span>
              R
            </p>

            <p className="uppercase font-extrabold">
              Every{" "}
              <span className="relative ">
                Youth{" "}
                <span className="text-sm font-normal bg-black px-2 py-1 absolute -bottom-2 right-0">
                  WITH ENGR. GABBY
                </span>
              </span>
            </p>

            <div className="text-xl mt-10">
              <p>
                18th Sept - 29th Sept - Skills For Every Youth Free Training
                with Engr. Gabby.
              </p>
            </div>
            <div className="flex justify-center items-center py-10">
              {/* <button className="text-xl border-2 border-red-700 font-light px-3 py-1 rounded-md hover:bg-red-700 hover:text-white">
                {" "}
                Register
              </button> */}
              <a
                href="#_"
                onClick={() => {
                  setValues({
                    ...values,
                    electrical_knowledge: electrical_knowledge[0],
                    interest: interested_courses[0],
                    qualification: highest_education_qualification[0],
                  });
                  setModal(true);
                }}
                class="relative px-6 py-3 font-bold text-white rounded-lg group text-sm text-center"
              >
                <span class="absolute inset-0 w-full h-full transition duration-300 transform -translate-x-1 -translate-y-1 bg-transparent border rounded-md  ease opacity-80 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:border-red-800"></span>
                <span class="absolute inset-0 w-full h-full transition duration-300 transform translate-x-1 translate-y-1 bg-red-800/40 rounded-md ease opacity-80 group-hover:translate-x-0 group-hover:translate-y-0 mix-blend-screen"></span>
                <span class="relative">Register</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="   text-white bg-[#e81211] min-h-[30vh] mx-10 -mt-10 pb-10 z-50 grid md:grid-cols-5 grid-cols-1 px-4 py-4 gap-5">
        <div>
          <p className="text-4xl flex flex-col">
            <span className="uppercase font-light  md:text-4xl ">
              skills f
              <span className="relative ">
                O
                <PlayCircleIcon className="absolute w-4 top-[17.5px]  right-[6.5px] font-bold text-black" />
              </span>
              r
            </span>
            <span className="uppercase font-bold"> every youth </span>
          </p>
          <p className="border-b-2 border-white  inline-block py-1 uppercase text-sm">
            {" "}
            2022 <span className="bg-black  px-1 "> with engr. gabby</span>
          </p>
          <p className="font-light text-sm py-3">
            First of its hands-on vocational skill development over 200
            participants were trained and certified on{" "}
            <span className="font-bold ">
              CCTV INSTALLATION AND SOLAR INVERTER{" "}
            </span>
            till date, the testimonies of lives that were transformed are still
            rolling in
          </p>
        </div>

        <div className="bg-[url('https://console.vitalskillsda.com/images/airconditioner.jpeg')] bg-center bg-cover min-h-[400px] w-full rounded-md  md:max-w-[320px]">
          <div className="bg-black/70  rounded-md relative w-full h-full">
            <p className="absolute uppercase hover:border-b-2 hover:border-white bottom-10 left-10 font-bold text-2xl md:text-2xl inline-block">
              CCTV INSTALLATION
            </p>
          </div>
        </div>
        <div className="bg-[url('https://console.vitalskillsda.com/images/solarman.jpeg')] bg-center bg-cover min-h-[400px] w-full rounded-md  md:max-w-[320px]">
          <div className="bg-black/70  rounded-md relative w-full h-full">
            <p className="absolute uppercase hover:border-b-2 hover:border-white  bottom-10 left-10 font-bold text-2xl md:text-2xl inline-block">
              SOLAR & INVERTER
            </p>
          </div>
        </div>
        <div className="bg-[url('https://console.vitalskillsda.com/images/cctv-mobile-phone.jpg')] bg-center bg-cover min-h-[400px] w-full rounded-md  md:max-w-[320px]">
          <div className="bg-black/70  rounded-md relative w-full h-full">
            <p className="absolute uppercase hover:border-b-2 hover:border-white bottom-10 left-10 font-bold text-2xl md:text-2xl inline-block">
              CCTV INSTALLATION INTO MOBILE DEVICE
            </p>
          </div>
        </div>
        <div className="bg-[url('https://console.vitalskillsda.com/images/solar-panel.jpeg')] bg-center bg-cover min-h-[400px] w-full rounded-md  md:max-w-[320px]">
          <div className="bg-black/70  rounded-md relative w-full h-full">
            <p className="absolute uppercase hover:border-b-2 hover:border-white  bottom-10 left-10 font-bold text-2xl md:text-2xl inline-block sm:font-light">
              SOLAR PANEL INSTALLATION
            </p>
          </div>
        </div>
      </div>

      <Modal1 visible={modal} onClose={() => setModal(false)}>
        {isProcessing === true ? (
          <div className="flex justify-center items-center">
            <ScaleLoader />
          </div>
        ) : (
          <div className="max-h-[50vh] overflow-scroll">
            <p className="font-bold text-3xl mb-5">
              Vital Skills Development Academy
            </p>
            <p className="text-gray-600 mb-5">
              Please fill in the relevant information
            </p>
            <form
              className="flex flex-col  gap-5 "
              onSubmit={handleSubmitRequest}
            >
              <FormInput
                id={"email"}
                placeholder={"Email"}
                value={values.email}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"firstname"}
                placeholder={"firstname"}
                value={values.firstname}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"lastname"}
                placeholder={"Last Name"}
                value={values.lastname}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"phoneNumber"}
                placeholder={"Phone"}
                value={values.phoneNumber}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"state"}
                placeholder={"State of Origin"}
                value={values.state}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"lga"}
                placeholder={"Local Government Area"}
                value={values.lga}
                onChange={handleFormOnChange}
              />
              <FormInput
                id={"address"}
                placeholder={"Residential Address"}
                value={values.address}
                onChange={handleFormOnChange}
              />
              {/* <FormInput
              id={"interest"}
              placeholder={"Technical Field of Interest"}
              value={values.interest}
              onChange={handleFormOnChange}
            /> */}

              <div>
                <p className="font-semibold">Interested Course</p>

                <select
                  id="interest"
                  onChange={handleEnrolmentFormOnChange}
                  className="py-4 px-2 outline-none"
                >
                  {interested_courses && interested_courses.length >= 1
                    ? interested_courses.map((data, index) => {
                        return <option value={data}>{data}</option>;
                      })
                    : null}
                </select>
              </div>
              <div>
                <p className="font-semibold">Electrical Experience</p>

                <select
                  id="electrical_knowledge"
                  onChange={handleEnrolmentFormOnChange}
                  className="py-4 px-2 outline-none"
                >
                  {electrical_knowledge && electrical_knowledge.length >= 1
                    ? electrical_knowledge.map((data, index) => {
                        return <option value={data}>{data}</option>;
                      })
                    : null}
                </select>
              </div>
              <div>
                <p className="font-semibold">Highest Education Qualification</p>

                <select
                  id="qualification"
                  onChange={handleEnrolmentFormOnChange}
                  className="py-4 px-2 outline-none"
                >
                  {highest_education_qualification &&
                  highest_education_qualification.length >= 1
                    ? highest_education_qualification.map((data, index) => {
                        return <option value={data}>{data}</option>;
                      })
                    : null}
                </select>
              </div>

              <button className="mt-5 btn"> Submit</button>
            </form>
          </div>
        )}
      </Modal1>

      <ToastContainer />
    </div>
  );
};

export default SkillsForEveryYouth;
