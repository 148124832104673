import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import MarketPlace from "./pages/MarketPlace";
import TechnicalTraining from "./pages/TechnicalTraining";
import Tutorial from "./pages/Tutorials";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import CourseList from "./pages/CourseList";
import CourseDetail from "./pages/CourseDetail";
import Skills from "./pages/Skills";
import OnlineStore from "./pages/OnlineStore";
import AboutUs from "./pages/AboutUs";
import PrivateRoutes from "./routes/PrivateRoutes";
import AssignJob from "./pages/Admin/AssignJob";
import Dashboard from "./pages/Admin/Dashboard";
import RequestForm from "./pages/RequestForm";
import { useEffect, useState } from "react";
import SplashScreen from "./pages/SplashScreen";
import SkillsForEveryYouth from "./pages/SkillsForEveryYouth";

function App() {
  return (
    <div className="">
      <Routes>
        {/* {localStorage.getItem("firstVisit") !== "1" && (
          <Route path="" element={<SplashScreen />} />
        )} */}
        <Route element={<PublicRoutes />}>
          <Route path="/about" element={<About />} />
          {/* <Route path="" element={<Contact />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:course" element={<CourseList />} />
          <Route path="/skills" element={<Skills />} />
          <Route
            path="/skills-for-every-youth"
            element={<SkillsForEveryYouth />}
          />
          <Route path="/online" element={<OnlineStore />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/course/detail/:id" element={<CourseDetail />} />
          {/* <Route
            path=""
            element={
              localStorage.getItem("firstVisit") === "1" ? (
                <Home />
              ) : (
                <SplashScreen />
              )
            }
          /> */}
          <Route path="" element={<Home />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/market-place" element={<MarketPlace />} />
          <Route path="/technical-traning" element={<TechnicalTraining />} />
          <Route path="/request-form" element={<RequestForm />} />
        </Route>

        <Route element={<PrivateRoutes />} path="/console">
          <Route element={<Dashboard />} path="" />
          <Route element={<AssignJob />} path="staffs" />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
